@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }

  @media only screen and (max-width: 425px) {
    .bg-image {
      background-size: cover !important;
    }
  }

  @media only screen and (min-width: 426px) {
    .bg-image {
      background-size: 100% 100% !important;
    }
  }
}

@layer utilities {
  .scrollbar-hidden {
    scrollbar-width: none;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .shadow-all {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
}